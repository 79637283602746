import getDistance from 'geolib/es/getDistance';

export let organizeArrayOfEventsByClosest = (arrayOfEvents, centerOfMap) => {
	let organizedArrayOfEvents = arrayOfEvents;
	
	if (arrayOfEvents?.daysEvents.length > 1) {
		for (let i = 0; i < arrayOfEvents.daysEvents.length; i++) {			
			organizedArrayOfEvents.daysEvents[i].distanceFromCenter = getDistance(
				    { latitude: parseFloat(arrayOfEvents.daysEvents[i].lat), longitude: parseFloat(arrayOfEvents.daysEvents[i].lng) },
				    { latitude: centerOfMap[0], longitude: centerOfMap[1] }
				)
		}
		
		organizedArrayOfEvents.daysEvents.sort(function(a, b) {
		    return a.distanceFromCenter - b.distanceFromCenter;
		});
		
		return organizedArrayOfEvents;
	}
	
	return arrayOfEvents;
}

