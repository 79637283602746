import React, { useState, useEffect }  from 'react';

import NextPreviousWeekButton from './nextPreviousWeekButton.js';
import DayButton from './dayButton.js';

const CalendarSelectorContainer = (props) => {
	const [firstDayOfWeekSelected, setFirstDayOfWeekSelected] = useState(0);
		
	let weeklyDays = props.daysInfo.slice(firstDayOfWeekSelected, firstDayOfWeekSelected+7);
	
	useEffect(() => {
		let firstDayFlag = 0;
		for (let i = 0; i < 13; i++) {
			if ((props.daySelected >= (i * 7)) && (props.daySelected < ((i+1) * 7))){
				setFirstDayOfWeekSelected((i * 7));
			} 
		}
	}, [props.daySelected]);
	
	const handleNextPrevClick = (e) => {
	  	if (e === 'right') {
		  	if (firstDayOfWeekSelected !== 84){
		  		setFirstDayOfWeekSelected(firstDayOfWeekSelected+7);
		  	}
	  	} else {
		  	if (firstDayOfWeekSelected !== 0) {
		  		setFirstDayOfWeekSelected(firstDayOfWeekSelected-7);
		  	}
	  	}
	}
	
	return (
		<div className='calendarSelectorContainer'>
			<NextPreviousWeekButton 
				handleClick={handleNextPrevClick}
				arrowDirection="left"
			/>
			{weeklyDays.map((day, index) => (
		        <DayButton 
		        	key={index}
		        	overallKey={index + firstDayOfWeekSelected}
			        dayOfWeek = {day.dayOfWeek}
					daysEvents = {day.daysEvents.length} 
					shorteneddate = {day.shorteneddate}
					isSelected = {(index + firstDayOfWeekSelected) === props.daySelected ? true : false}
					dayOnClick = {props.dayOnClick}
		        />
		    ))}
			<NextPreviousWeekButton 
				handleClick={handleNextPrevClick}
				arrowDirection="right"
			/>
		</div>
	);
};

export default CalendarSelectorContainer;