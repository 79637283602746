import React, { useState, useContext, useRef } from 'react';

import Event from './event.js';

const EventsScroll = (props) => {
	
	if (props?.events?.daysEvents.length > 0) {
		//console.log(props?.events?.daysEvents[0].lat);
		//console.log(props?.events?.daysEvents[0].lng);
		//console.log(props.latLng);
		// list.sort((a, b) => (a.color > b.color) ? 1 : -1)
		
		//console.log(props?.events?.daysEvents)
	}
	return (
		<div className="eventScroll-container">
			<div className="eventScroll-events-wrapper" >
			{props?.events?.daysEvents.map((event, index) => (
		        <Event key={index} eventData={event} handleClickHandler={props.handleClickHandler}/>
		    ))}	
		    </div>
		    <a 
				tabindex={0} 
				href='https://funlocally.com/events/' 
				target='_blank' 
				className='addButton entypo-plus-squared add-event-button"'
				alt='Add Event'
			/>
		</div>
	);
};

export default EventsScroll;