import React, { useState, useEffect, useRef } from 'react';

const TopNav = (props) => {
	
	const searchStateHandler = (event) => {
	  	props.setSearchState(event);
	}
	
	return (
		<div>
			<div className={props.searchState === 'showTitle' ? 'topNav topNavSearchViewShow' : 'topNav topNavSearchViewHide'}>
				<span 
					className='funlocallyTitle'
				>
					funlocally
				</span>
				<div
					onClick={(e)=>props.searchPremiumOrStandardHandler(props.searchPremiumOrStandard)}
					className={props.searchPremiumOrStandard === 'all'? 'funlocallyPlus entypo-star-empty' : 'funlocallyPlus entypo-star funlocallyGoldStar'}
				/>
				<div
					onClick={(e)=>{
						searchStateHandler('showSearch');
						props.clearSearch();
					}}
					className='funlocallyPlus entypo-search'
				/>
				
			</div>
			<div className={props.searchState === 'showSearch' ? 'topNav topNavSearchViewShow' : 'topNav topNavSearchViewHide'}>
				<input type='text' 
					className='searchBox'
					onChange={props.searchBoxHandler}
					value={props.searchValue}
					placeholder='@handle or #hashtag or keyword'
				/>
				<div
					onClick={(e)=>props.searchPremiumOrStandardHandler(props.searchPremiumOrStandard)}
					className={props.searchPremiumOrStandard === 'all'? 'funlocallyPlus entypo-star-empty' : 'funlocallyPlus entypo-star funlocallyGoldStar'}
				/>
				<div
					onClick={(e)=>{
						searchStateHandler('showTitle');
						props.clearSearch();
					}}
					className='funlocallyPlus entypo-cancel'
				/>
				
			</div>
		</div>
	);
};

export default TopNav;