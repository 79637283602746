import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './logo.svg';
import './App.css';
import moment from 'moment';

import TopNav from './components/topNav.js';
import EventsScroll from './components/eventsScroll.js';
import MapClassic from './components/mapClassic.jsx';
import CalendarSelectorContainer from './components/calendarSelectorContainer.js';

import { organizeArrayOfEventsByClosest } from './utils/eventsOrganize.js';
import { ENVIRONMENT } from './utils/prodTestVars.js'
import 'url-search-params-polyfill';


function App() {
  const [allEvents, setAllEvents] = useState([]);
  const [workingListOfEvents, setWorkingListOfEvents] = useState([]);
  const [daysInfo, setDaysInfo] = useState([]);
  const [allEventsBounds, setAllEventsBounds] = useState([]);
  const [allEventsHandleSearch, setAllEventsHandleSearch] = useState([]);
  const [allEventsKeywordSearch, setAllEventsKeywordSearch] = useState([]);
  const [allEventsPremiumOrStandard, setAllEventsPremiumOrStandard] = useState([]);
  const [daysInfoWithBounds, setDaysInfoWithBounds] = useState([]);
  const [dayPrevSelected, setDayPrevSelected] = useState(0);
  const [daySelected, setDaySelected] = useState(0);
  const [latLng, setLatLng] = useState([39,-98]);
  const [finalBounds, setFinalBounds] = useState({});
  const [mobileButtonStatus, setMobileButtonStatus] = useState('hideEvents');
  const [zoomLevel, setZoomLevel] = useState(4);
  const [paramsFlag, setParamsFlag] = useState(false);
  const [queryZoom, setQueryZoom] = useState(4);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchPremiumOrStandard, setSearchPremiumOrAll] = useState('all');
  const [searchState, setSearchState] = useState('showTitle');

  useEffect(() => {
    let daysArray, archiveFiles, archiveArray = [];
		axios.get(ENVIRONMENT.API_URL_ROOT + '/rewrite_getAllEvents.php?init=true')
			.then(res => {
				daysArray = [];
				
				const files = res.data.data;     
				archiveFiles = files;
				
				for (let i = 0; i < archiveFiles.length; i++) {
					archiveArray.push({
						title: archiveFiles[i][0],
						venue: archiveFiles[i][1],
						descry: archiveFiles[i][2],
						whenisit: archiveFiles[i][3],
						lat: archiveFiles[i][4],
						lng: archiveFiles[i][5],
						img_path: archiveFiles[i][6],
						eventCat: archiveFiles[i][7],
						likes: archiveFiles[i][8],
						eventDate: archiveFiles[i][9],
						id: archiveFiles[i][10],
						eventDateUnix: archiveFiles[i][11],
						status: archiveFiles[i][12],
						handle: archiveFiles[i][13],
						url_img: archiveFiles[i][14],
						tracking_link: archiveFiles[i][15],
						min_ticket_price: archiveFiles[i][16]                                
					})
				}
				
				for(let i = 0; i < 91; i++){
					let addedDays = moment().add(i, 'days')
					daysArray.push({
						isSelected: i == daySelected ? true : false,
						fullDate: addedDays.format('L'),
						shorteneddate: addedDays.format('M[/]DD'),
						urldate: addedDays.format('MM[-]DD'),
						dayOfWeek: addedDays.format('ddd'),
						daysEvents: []
						
					})
				}
				
				for(let i = 0; i < daysArray.length; i++){
					for(let j = 0; j < archiveArray.length; j++){
						if (daysArray[i].fullDate === archiveArray[j].eventDate) {
							daysArray[i].daysEvents.push(archiveArray[j]);
						}
					}	
				}
				
				

				setAllEvents(archiveArray);
				setAllEventsBounds(archiveArray)
				setAllEventsHandleSearch(archiveArray)
				setAllEventsKeywordSearch(archiveArray)
				setAllEventsPremiumOrStandard(archiveArray)
				setWorkingListOfEvents(archiveArray);
				
				setDaysInfo(daysArray);
				setDayPrevSelected(daySelected);
				setDaySelected(0);
				
				if (typeof window !== 'undefined') {
					
					let queryParams = new URLSearchParams(window.location.search);

					if(queryParams.toString() === ""){
						setDayPrevSelected(daySelected);
						setDaySelected(0);
						
						if (localStorage.getItem("lat") !== null && localStorage.getItem("lng") !== null) {
							let lat = parseFloat(localStorage.getItem("lat"))
							let lng = parseFloat(localStorage.getItem("lng"))
							let zoom = parseFloat(localStorage.getItem("zoom"))
							
							setZoomLevel(zoom);
							setLatLng([lat, lng]);
						}
					
					} else {
						let urlDaySelected = 0;
						let lat = latLng[0];
						let lng = latLng[1];
						let handle = '';
						let zoom = zoomLevel;
						for (let p of queryParams) {
							if (p[0] === "date") {
								for (let i = 0; i < daysArray.length; i++) {
									daysArray[i].isSelected = false;
									if (daysArray[i].urldate === p[1]) {
										urlDaySelected = i;
										daysArray[i].isSelected = true;
									}
								}
							}
							
							if (p[0] === "lat") {
								setZoomLevel(14);
								lat = parseFloat(p[1]);
							}
							
							if (p[0] === "lng") {
								lng = parseFloat(p[1]);
							}
							
							if (p[0] === "handle") {
								handle = p[1];
								setSearchValue(handle+"+");
							}
						}
						setParamsFlag(true);
						setLatLng([lat, lng]);
						
						setDayPrevSelected(0);
						setDaySelected(urlDaySelected);
					}
				} else {
					setDayPrevSelected(daySelected);
					setDaySelected(0);
					
				}
				
				setLoading(true);
			})
			.catch(err => {
				setLoading(true);
			})

  }, []);
  
	const daySelectedHelper = () => {
		let newDaysInfo = daysInfo;
		newDaysInfo[dayPrevSelected].isSelected = false;
		newDaysInfo[daySelected].isSelected = true;
		setDaysInfo(newDaysInfo);  
	}
	
	/*
	*	set which day is selected by user
	*/
	
	useEffect(() => {
		if (daysInfo[daySelected]?.daysEvents.length > 0 || daysInfo[daySelected]?.daysEvents) {
			daySelectedHelper();
		}
	}, [daySelected]);
	
	
	/*
	*	set all events that match selected All or Premium events
	*	
	*/
	
	useEffect(() => {
		if (searchPremiumOrStandard === 'all') {
			let premiumOrStandardSearch = allEvents;
			setAllEventsPremiumOrStandard(premiumOrStandardSearch);
		} else if (searchPremiumOrStandard === 'premium') {
			let premiumOrStandardSearch = workingListOfEvents.filter(function (e) {
				return (e.eventCat === 'premium')
			});
			setAllEventsPremiumOrStandard(premiumOrStandardSearch);
		}
		
	}, [searchPremiumOrStandard]);
	
	
	/*
	*	set all events that matches keyword
	*	or 
	*	set all events that matches handle
	*/
	
	useEffect(() => {
		let firstCharacter = searchValue.charAt(0);
		let lastCharacter = searchValue.charAt(searchValue.length-1);
		let handleSearch;
		
		if (firstCharacter === '@' && searchValue.length > 1) {
			let handle = searchValue.substring(1);
			
			handleSearch = allEvents.filter(function (e) {
				return (e.handle.includes(handle))
			});
			
			if (lastCharacter === '+') {
				handle = handle.substring(0, handle.length - 1);
				 
				handleSearch = allEvents.filter(function (e) {
					return (e.handle === handle);
				});
			}
			setAllEventsHandleSearch(handleSearch);
			
		} else if (searchValue.length === 0) {
			setAllEventsHandleSearch(allEvents);
			setAllEventsKeywordSearch(allEvents);
		} else if (firstCharacter !== '@') {
			let keyword = searchValue;
			let keywordSearch = allEvents.filter(function (e) {
				let lowerCaseDescry = e.descry.toLowerCase();
				let lowerCaseTitle = e.title.toLowerCase();
				let lowerCaseKeyword = keyword.toLowerCase();
				return (lowerCaseDescry.includes(lowerCaseKeyword) || lowerCaseTitle.includes(lowerCaseKeyword))
			});
			
			setAllEventsKeywordSearch(keywordSearch)
		} 

	}, [searchValue]);
	
	
	/*
	*	set all events with in map bounds
	*/
	
	useEffect(() => {
		let daysArray = [];
		let allEventsWithinBounds = allEvents.filter(function (e) {
			return (parseFloat(e.lat) > finalBounds.lat[0] && 
					parseFloat(e.lat) < finalBounds.lat[1] && 
					parseFloat(e.lng) > finalBounds.lng[0] && 
					parseFloat(e.lng) < finalBounds.lng[1])
		});
		
		
		setAllEventsBounds(allEventsWithinBounds); 
	}, [finalBounds]);

	
	/*
	*	create an array with each days information
	*	setDaysInfo state to array created
	*/
	
	useEffect(() => {
		let daysArray = [];
		
		for(let i = 0; i < 91; i++){
	        let addedDays = moment().add(i, 'days')
	        daysArray.push({
		        isSelected: i == daySelected ? true : false,
		        fullDate: addedDays.format('L'),
				shorteneddate: addedDays.format('M[/]DD'),
				dayOfWeek: addedDays.format('ddd'),
				daysEvents: []
				
	        })
        }
		        
		for(let i = 0; i < daysArray.length; i++){
		   	for(let j = 0; j < workingListOfEvents.length; j++){
			    if (daysArray[i].fullDate === workingListOfEvents[j].eventDate) {
			    	daysArray[i].daysEvents.push(workingListOfEvents[j]);
		    	}	
		   	}
		}	
		
		setDaysInfo(daysArray);
	}, [workingListOfEvents]);	
	
	
	/*
	*	Compare allEventsBounds, allEventsHandleSearch, allEventsKeywordSearch, allEventsPremiumOrStandard
	* 	return all events that are present in all lists and update setWorkingListOfEvents
	*/
	
	useEffect(() => {

		let firstLevel = [];
		let secondLevel = [];
		let allWorkingEvents = [];
		
		for(let b=0 ; b<allEventsBounds.length ; ++b) {
			for(let h=0 ; h<allEventsHandleSearch.length ; ++h) {
				if(Object.is(allEventsBounds[b], allEventsHandleSearch[h])) {  
				    firstLevel.push(allEventsBounds[b]);        
				}
			}
		}

		for(let b=0 ; b<firstLevel.length ; ++b) {
			for(let k=0 ; k<allEventsKeywordSearch.length ; ++k) {
				if(Object.is(firstLevel[b], allEventsKeywordSearch[k])) {  
				    secondLevel.push(allEventsKeywordSearch[k]);        
				}
	
			}
		}
		
		for(let b=0 ; b<secondLevel.length ; ++b) {
			for(let p=0 ; p<allEventsPremiumOrStandard.length ; ++p) {
				if(Object.is(secondLevel[b], allEventsPremiumOrStandard[p])) {  
				    allWorkingEvents.push(allEventsPremiumOrStandard[p]);        
				}
			}
		}
		
		setWorkingListOfEvents(allWorkingEvents);

	}, [allEventsBounds, allEventsHandleSearch, allEventsKeywordSearch, allEventsPremiumOrStandard]);
  
	const handleDayClick = (e) => {
		setDayPrevSelected(daySelected)
		setDaySelected(e);
	}
  
  	const handleGetBounds = (getBounds) => {
		setFinalBounds(getBounds)
  	}
  	
  	const mapButtonClicked = () => {
	  	setMobileButtonStatus('hideEvents')
	}
	
	const eventsButtonClicked = () => {
	  	setMobileButtonStatus('hideMap')
	}
	
	const clearSearch = () => {
	  	setAllEventsHandleSearch(allEvents);
		setAllEventsKeywordSearch(allEvents);
		setSearchValue('')
	}
	
	const searchBoxHandler = (e) => {
	  	setSearchValue(e.target.value);
	}
	
	const searchPremiumOrStandardHandler = (premiumeOrAll) => {
		if (premiumeOrAll === 'all') {
			setSearchPremiumOrAll('premium');
		} else if (premiumeOrAll === 'premium') {
			setSearchPremiumOrAll('all');
		}
	}
	
	const handleClickHandler = (userHandle) => {
		setSearchState('showSearch');
		setMobileButtonStatus('hideEvents')
		setSearchValue('@'+userHandle+'+')
	}
  
  return (
    <div className="App">
    	<div className="mobileView">
	    	
	    	{mobileButtonStatus === "hideEvents" && loading &&
		    	<div className="mobileBodyContainer">
		    		<TopNav 
		    			clearSearch={clearSearch}
		    			searchBoxHandler={searchBoxHandler}
		    			searchValue={searchValue}
		    			searchPremiumOrStandard={searchPremiumOrStandard}
		    			searchPremiumOrStandardHandler={searchPremiumOrStandardHandler}
		    			searchState={searchState}
		    			setSearchState={setSearchState}
		    		/> 
		    		<div className="mapCalendarContainer">			 
					    <MapClassic
					    	events={daysInfo[daySelected]}
					    	daysInfo={daysInfo}
					    	latLng={latLng}
					    	setLatLng={setLatLng}
					    	setZoomLevel={setZoomLevel}
					    	zoomLevel={zoomLevel}
					    	handleGetBounds={handleGetBounds}
					    	queryZoom={queryZoom}
					    />
					    
					    
				    </div>   
				</div>
			}
			{mobileButtonStatus === "hideMap" && loading &&
			    <EventsScroll
			    	events={organizeArrayOfEventsByClosest(daysInfo[daySelected], latLng)}
			    	latLng={latLng}
			    	handleClickHandler={handleClickHandler}
			    />	    
			}
			
			<CalendarSelectorContainer 
		    	daysInfo={daysInfo}
		    	daySelected={daySelected}
		    	dayOnClick={handleDayClick}
		    />
		    <div className="mobileNavButtonContainer">
				<div onClick={mapButtonClicked} className={mobileButtonStatus === 'hideEvents' ? 'mobileNavButton entypo-globe navButtonSelected' : 'mobileNavButton entypo-globe'}>
				</div>
				
				<div onClick={eventsButtonClicked} className={mobileButtonStatus === 'hideMap' ? 'mobileNavButton entypo-doc-text-inv navButtonSelected' : 'mobileNavButton entypo-doc-text-inv'}>
				</div> 
				<a 
					href="https://funlocally.com/events/" 
					className='mobileNavButton entypo-plus-squared add-event-button'
					target='_blank' 
					alt='Add Event'
				/>    	
			</div>
			
			
		</div>
		<div className="deskTopView">
			<TopNav 
    			clearSearch={clearSearch}
    			searchBoxHandler={searchBoxHandler}
    			searchValue={searchValue}
    			searchPremiumOrStandard={searchPremiumOrStandard}
    			searchPremiumOrStandardHandler={searchPremiumOrStandardHandler}
    			searchState={searchState}
		    	setSearchState={setSearchState}
    		/> 
			<div className="desktopBodyContainer"> 
	    		<div className="mapCalendarContainer">			 
				    <MapClassic
				    	events={daysInfo[daySelected]}
				    	daysInfo={daysInfo}
				    	latLng={latLng}
				    	setLatLng={setLatLng}
				    	setZoomLevel={setZoomLevel}
				    	zoomLevel={zoomLevel}
				    	handleGetBounds={handleGetBounds}
				    	queryZoom={queryZoom}
				    />
				    
				    <CalendarSelectorContainer 
				    	daysInfo={daysInfo}
				    	daySelected={daySelected}
				    	dayOnClick={handleDayClick}
				    />
			    </div>   
			</div>
			<EventsScroll
		    	events={organizeArrayOfEventsByClosest(daysInfo[daySelected], latLng)}
		    	latLng={latLng}
		    	handleClickHandler={handleClickHandler}
		    />
		
		</div>
    </div>
  );
}

export default App;
