import React from 'react';

const DayButton = (props) => {
	return (
		<button 
			onClick={() => props.dayOnClick(props.overallKey)} 
			className={props.isSelected === true ? 'calendarButton dayButton dayIsSelected' : 'calendarButton dayButton'}>
			<div className='dayData'>{props.dayOfWeek}</div>
			<div className='dayData'>{props.shorteneddate}</div>
			<div className='dayData'>{props.daysEvents}</div>
		</button>
	);
};

export default DayButton;