const CONSTANTS = {
	test: {
		API_URL_ROOT: 'https://leeple.com/draftspecials',
		FLYER_PATH: '..',
		ROUTE_PATH: 'events/'
	},
	live: {
		API_URL_ROOT: 'https://funlocally.com',
		FLYER_PATH: '..',
		ROUTE_PATH: ''
	},
	staging: {
		API_URL_ROOT: 'https://leeple.com/draftspecials',
		FLYER_PATH: '..',
		ROUTE_PATH: ''
	}
}

export const ENVIRONMENT = CONSTANTS.live;