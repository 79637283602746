import React, { Component } from 'react';
import PropTypes from 'prop-types';

import L from 'leaflet';
import { Map, GeoJSON, TileLayer, Marker, Popup, ZoomControl} from 'react-leaflet'
import moment from 'moment';
import 'leaflet/dist/leaflet.css';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';


var myIcon = L.divIcon({
	className: 'my-div-icon',
	iconAnchor: [-0, 70],
	popupAnchor: [35, -33]
	
	});


let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [12.5, 40],
    popupAnchor: [0, -40]
});

let date = new Date();
new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();

class MapClassic extends React.Component {
	constructor(props) {
	  super(props);
	  this.state = {
		lat: 29.4241,
		lng: -98.4936,
		zoom: null,
		zoomTrigger: null,
		titleCharMax: 60
	  };
	  
	  this.handleMoveEnd = this.handleMoveEnd.bind(this);
	  this.handleZoomstart = this.handleZoomstart.bind(this);

	  this.markerRef = React.createRef();
	}
	
	componentDidUpdate(prevProps, prevState){		
		if (this.state.zoom !== prevState.zoom) {
		    this.setState({
				zoomTrigger: this.state.zoom
			})
		}
	}
	
	componentDidMount(){
		let bounds = this.refs.map.leafletElement.getBounds();
		let zoom = 10;
		let lat = 28.12;
		let lng = -98.23;
		
		let boundsObject = {
			lat: [bounds._southWest.lat, bounds._northEast.lat],
			lng: [bounds._southWest.lng, bounds._northEast.lng]
		}
		
		if (typeof window !== 'undefined') {
	    	let queryParams = new URLSearchParams(window.location.search);

			if (queryParams.get('zoom')) {
				this.props.setZoomLevel(zoom);
			}
		}
		
		this.setState({
			zoomTrigger: zoom
		})
	}
	
	
	handleMoveEnd = (e) => {
		let bounds = this.refs.map.leafletElement.getBounds();
		let center = this.refs.map.leafletElement.getCenter();
		let zoomLevel = this.refs.map.leafletElement.getZoom();
		if (bounds._northEast.lat !== bounds._southWest.lat) {
			let boundsObject = {
				lat: [bounds._southWest.lat, bounds._northEast.lat],
				lng: [bounds._southWest.lng, bounds._northEast.lng]
			}
			this.props.setLatLng([center.lat, center.lng]);
			
			localStorage.setItem('lat', center.lat);
			localStorage.setItem('lng', center.lng);
			localStorage.setItem('zoom', zoomLevel);

			this.props.handleGetBounds(boundsObject)
		}
	}
	
	handleZoomstart = (e) => {
    	this.props.setZoomLevel(this.refs.map.leafletElement.getZoom());
	}
	
	handleMarkerClick = event => {
	    const { lat, lng } = event.latlng;
	    	    
	    this.props.setLatLng([lat, lng]);
		localStorage.setItem('lat', lat);
		localStorage.setItem('lng', lng);
	  }
  
	render() {
		return (
			<Map 
				ref='map'
				center={ [parseFloat(this.props.latLng[0].toFixed(4)), parseFloat(this.props.latLng[1].toFixed(4))]}
				zoom={this.props.zoomLevel}
				onZoomEnd={this.handleZoomstart}
				onMoveEnd={this.handleMoveEnd}
				zoomControl={false}
				maxZoom={21}
			>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
					url='https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?style=mapbox://styles/mapbox/streets-v11@00&access_token=pk.eyJ1IjoicmFtb25lMjAwMSIsImEiOiJjbGZvaXJvd2MwdzQzM3Jsa3VpY3g3YXMxIn0.AQ_0NmnAWj3etSHNHbxbLQ'
					id= 'mapbox/streets-v11'
					tileSize= '512'
					zoomOffset= {-1}
				/>
				<ZoomControl position="bottomright" />
				<div className="supportLegend">
				    
				</div>
				{this.props?.events?.daysEvents.map((event, index) => (
					<div key={index} >
						<Marker 
							position={[event.lat, event.lng]}
							draggable={false}
							icon={L.divIcon({
								className: event.eventCat === 'premium' ? 'my-div-icon premiumIcon' : 'my-div-icon',
								iconAnchor: [-0, 115],
								popupAnchor: [35, -33],
								html: event.tracking_link !== "" ?
								'<div class="map-label "><div class="ticketStatus"><a href='+event.tracking_link+' target="_blank">Tickets</a></div><div class="map-label-title">'+(event.title.length > this.state.titleCharMax ? event.title.substring(0,this.state.titleCharMax) + "..." : event.title)+'</div><div class="map-label-whenisit">'+moment(event.whenisit).format('LT')+'</div></div>' :
								'<div class="map-label "><div class="map-label-title">'+(event.title.length > this.state.titleCharMax ? event.title.substring(0,this.state.titleCharMax) + "..." : event.title)+'</div><div class="map-label-whenisit">'+moment(event.whenisit).format('LT')+'</div></div>'
													
							})}
							onClick={this.handleMarkerClick}
							zIndexOffset={index === 0 ? 100000 : -10}
						> 
					    </Marker>
				    </div>
			    ))}				
			</Map>	

       )
	}
}

MapClassic.propTypes = {
	events: PropTypes.array.isRequired,
	dayInfo: PropTypes.array.isRequired,
	handleGetBounds: PropTypes.func.isRequired
}

export default MapClassic;