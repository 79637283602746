import React, { useState } from 'react';
import moment from 'moment';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ENVIRONMENT } from '../utils/prodTestVars.js'

const Events = (props) => {
	const [copiedState, setCopiedState] = useState('Event Url');
	
	//console.log(props.eventData);
	const clickLink = (e) => {
		
		setTimeout(
		    function() {
		        setCopiedState('Event Url');
		    }
		    .bind(this),
		    1000
		);	
	}
	return (
		<div className={props.eventData.eventCat === 'premium' ? 'badge archiveBadge premium' : 'badge archiveBadge'}>
			<div className='user' onClick={(e) => props.handleClickHandler(props.eventData.handle)}>
				<img className="userImage" src={props.eventData.url_img}/>
				<div className="userName" >{props.eventData.handle}</div>
			</div>
			
			<div className='docTime'>
				{moment(props.eventData.whenisit).format('LT')}
				
				<CopyToClipboard 
						text={'https://www.funlocally.com/?date='+moment(props.eventData.whenisit).format('MM-DD')+'&lat='+ parseFloat(props.eventData.lat).toFixed(5)+'&lng='+ parseFloat(props.eventData.lng).toFixed(5)} 
						onCopy={() => setCopiedState('Copied')}
					>
						<div className='eventUrl' onClick={clickLink()}>{copiedState}</div>
				</CopyToClipboard>
			</div>
			
			<div className='docTitle'>
				{props.eventData.title}
			</div>
			<div className="badgeImgContainer">
				<img className="badgeImg" src={props.eventData.img_path === 'null' ? 'https://funlocally.com/FL.png' : ENVIRONMENT.API_URL_ROOT +`${props.eventData.img_path}` } /> 
			</div>
			<div className="descriptionContainer">
				{props.eventData.tracking_link !== "" &&
					<div className="badgeTicketStatus">
						<a href={props.eventData.tracking_link} target="_blank">Tickets<br />
						{props.eventData.min_ticket_price !== "" &&
							<div className="ticketPrices">Starting At {props.eventData.min_ticket_price}</div>
						}
						</a>
					</div>

				}

				<div className="description">
					{props.eventData.descry}
				</div>
			</div>

		</div>
	);
};

export default Events;