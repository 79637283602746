import React from 'react';

const NextPreviousWeekButton = (props) => {
	return (
		<button 
			className={props.arrowDirection === 'right' ? 'calendarButton nextPreviousWeekButton entypo-right-dir' : 'calendarButton nextPreviousWeekButton entypo-left-dir'}
			onClick={() => props.handleClick(props.arrowDirection)} 
		>

		</button>
	);
};

export default NextPreviousWeekButton;